import $ from "jquery";

$(document).ready(() => {
  $('[data-scroll="wrapper"]').on("click", () => {
    $("html, body").animate(
      {
        scrollTop:
          $('[data-scroll="target"]').offset().top -
          $("#header-container").outerHeight() -
          20,
      },
      200
    );
  });
});
