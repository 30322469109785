import $ from "jquery";
import SeminarsLibrary from "./libary";

$(document).ready(() => {
  const bundledCoursesElement = $(".bundled-courses");

  if (bundledCoursesElement.length) {
    const library = new SeminarsLibrary();

    const ajaxUrl = bundledCoursesElement.data("seminar-ajax-url");
    const currentAppointment = bundledCoursesElement.data("current-appointent");
    let json = bundledCoursesElement.data("seminar-json");
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    library.setSeminarJson(json);
    library.setAjaxUrl(ajaxUrl);
    library.startAvailabilityCheck();

    library.initCalendar();

    if (currentAppointment) {
      library.setClickEventOnCurrentAppointment(currentAppointment);
    }
  }
});
