import $ from "jquery";
import "datatables.net-dt";

$(document).ready(() => {
  const initDataTableWorldWide = () => {
    const visibilityRows = 4;
    const container = $(".seminarsWorldWide");
    const table = $(".seminarsWorldWide-table");

    const languageData = {
      noResults: container.data("translation-no-result"),
      search: container.data("translation-search"),
      previousPage: container.data("translation-previous-page"),
      nextPage: container.data("translation-next-page"),
    };

    $("#pageId").hide();

    table.dataTable({
      bInfo: false,
      bAutoWidth: false,
      bStateSave: false,
      bProcessing: false,
      oLanguage: {
        sSearch: `${languageData.search}: `,
        sZeroRecords: languageData.noResults,

        oPaginate: {
          sPrevious: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"><path class="icon icon__chevron icon--small icon--pagination icon--thin" d="M7,1L1,7L7,13"></path></svg><span>${languageData.previousPage}</span>`,
          sNext: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"><path class="icon icon__chevron icon--small icon--pagination icon--thin" d="M1,1L7,7L1,13"></path></svg><span>${languageData.nextPage}</span>`,
        },
      },

      aaSorting: [[0, "asc"]],

      aoColumns: [
        { bSortable: true, bVisible: true, sWidth: "30%" }, // 0 flag
        { bSortable: false, bVisible: true, sWidth: "70%" }, // 1 location
        { bSortable: false, bVisible: false }, // 2 category
        { bSortable: true, bVisible: false }, // 3 zip code for sorting
      ],
      bLengthChange: false,
      bFilter: true,
      bSort: true,
      pagingType: "simple_numbers",
      iDisplayLength: visibilityRows,
    });

    table.removeAttr("hidden");
  };

  /**
   * observes filter and links
   *
   * @todo optimize code
   * @return {void}
   */
  const observeAndHandleFilters = () => {
    // Mobil: enable toggle Event
    $(".js-facets-visibility").on("click", () => {
      $(".js-facets-wrapper").toggleClass("visible");
    });

    const facets = {
      0: "country",
      2: "category",
    };

    // General: enable all facets events
    $.each(facets, (index, value) => {
      const facetSelector = $(`.js-facet-${value}`);
      const headerSelector = $(`.js-facet-${value}-header`);
      const containerSelector = $(`.js-${value}-container`);
      const facetsContainerSelector = $(".js-facet-container");

      facetSelector.on("click", (e) => {
        const element = $(e.currentTarget);
        const facetValue = element.attr("data-val");
        const filterName = element.attr("data-facet");
        const table = $(".dataTable").dataTable();

        if (element.hasClass("active")) {
          facetSelector.removeClass("active");
          headerSelector.removeClass("active");
          table.fnFilter("", index);
          table.fnSort([0, "asc"]);
        } else {
          facetSelector.removeClass("active");
          element.addClass("active");
          headerSelector.addClass("active");
          table.fnFilter(facetValue, index);

          if (filterName === "country") {
            table.fnSort([3, "asc"]);
          }
        }
      });

      headerSelector.on("click", (e) => {
        if (window.innerWidth >= 992) {
          // Desktop: enable disable event for header title
          if ($(e.currentTarget).hasClass("active")) {
            facetSelector.removeClass("active");
            headerSelector.removeClass("active");
            $(".dataTable").dataTable().fnFilter("", index);
          }
        } else if (containerSelector.hasClass("visible")) {
          containerSelector.removeClass("visible");
        } else {
          facetsContainerSelector.removeClass("visible");
          containerSelector.addClass("visible");
        }
      });
    });
  };

  const documentReadyInit = () => {
    initDataTableWorldWide();
    observeAndHandleFilters();
  };

  if ($(".seminarsWorldWide").length) {
    documentReadyInit();
  }
});
