/* global jconfirm */

import $ from "jquery";
import "datatables.net-dt";
import "./jquery-confirm-custom";
import SeminarsLibrary from "./libary";

$(document).ready(() => {
  /**
   * init dataTables
   *
   * @return {void}
   */
  const initDataTableAnnualOverview = () => {
    let visibilityRows = 100;
    if (window.innerWidth <= 992) {
      visibilityRows = 5;
    }

    const container = $(".annualOverview");
    const ajaxUrl = container.data("seminar-ajax-url");
    const library = new SeminarsLibrary();
    library.setAjaxUrl(ajaxUrl);

    $(".js-appointment-information-button").on("click", (e) => {
      const link = $(e.currentTarget)
        .parent()
        .find(".js-detail-page-link")
        .attr("href");
      const details = $(e.currentTarget)
        .parent()
        .find(".js-annualOverview--course-details")
        .html();

      jconfirm({
        columnClass: "col-md-12",
        theme: "dark",
        animation: "zoom",
        backgroundDismiss: true,
        title: "",
        link,
        content: details,
        buttons: {
          ok: {
            action: () => {
              // do nothing
            },
          },
        },
        onContentReady: () => {
          let json = $(details)
            .find("[data-seminar-json]")
            .data("seminar-json");
          if (typeof json === "string") {
            json = JSON.parse(json);
          }
          library.setSeminarJson(json);
          library.startAvailabilityCheck(".jconfirm");
        },
        onOpen: () => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "overlay.open",
            overlayname: $(details).find(".seminar_detail-title").text().trim(),
          });
        },
        onClose: () => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: "overlay.close" });
        },
      });
    });

    // add custom wrapper class
    $.fn.dataTableExt.oStdClasses.sWrapper = "data-table--annualOverview";
    $.fn.dataTableExt.oStdClasses.sFilter = "data-table--search";
    const table = $(".dataTable");
    table.DataTable({
      paging: true,

      pageLength: 12,

      sDom: '<"top"flp>rt<"bottom"i><"clear">',

      fnDrawCallback: (oSettings) => {
        if (oSettings["_iDisplayLength"] > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
        }
        if (oSettings["_iDisplayLength"] < oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
        }
      },

      oLanguage: {
        sSearch: "",
        sZeroRecords: container.data("translation-no-results"),
        sInfoFiltered: "",
        oPaginate: {
          sPrevious: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"><path class="icon icon__chevron icon--small icon--pagination icon--thin" d="M7,1L1,7L7,13"></path></svg><span>${container.data(
            "translation-previous-page"
          )}</span>`,
          sNext: `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14"><path class="icon icon__chevron icon--small icon--pagination icon--thin" d="M1,1L7,7L1,13"></path></svg><span>${container.data(
            "translation-next-page"
          )}</span>`,
        },
      },

      bInfo: false,

      aoColumns: [
        { bSortable: false, sWidth: "100%" }, // 0 Kurstitel
        { bSortable: false, bVisible: false }, // 1 Kategorie
        { bSortable: false, bVisible: false }, // 2 Steuerung filter
        { bSortable: false, bVisible: false }, // 3 Kursart filter
        { bSortable: false, bVisible: false }, // 4 monate filter
        { bSortable: false, bVisible: false }, // 5 level filter
      ],

      bLengthChange: false,
      bFilter: true,
      bSort: false,
      bAutoWidth: false,
      pagingType: "simple_numbers",
      iDisplayLength: visibilityRows,
    });

    table
      .css("display", "")
      .wrap(
        '<div class="annualOverview--table-wrapper" data-view="annualOverview"></div>'
      );
  };

  /**
   * observes filter and links
   *
   * @todo optimize code
   * @return {void}
   */
  const observeAndHandleFilters = () => {
    // Mobil: enable toggle Event
    $(".js-facets-visibility").on("click", () => {
      $(".js-facets-wrapper").toggleClass("visible");
    });

    const facets = {
      1: "category",
      2: "event",
      3: "course",
      4: "month",
      5: "level",
    };

    // General: enable all facets events
    $.each(facets, (index, value) => {
      const facetSelector = $(`.js-facet-${value}`);
      const headerSelector = $(`.js-facet-${value}-header`);
      const containerSelector = $(`.js-${value}-container`);
      const facetsContainerSelector = $(".js-facet-container");

      facetSelector.on("click", (e) => {
        const element = $(e.currentTarget);
        const facetValue = element.attr("data-val");
        const table = $(".dataTable");

        if (element.hasClass("active")) {
          facetSelector.removeClass("active");
          headerSelector.removeClass("active");
          table.dataTable().fnFilter("", index);
        } else {
          facetSelector.removeClass("active");
          element.addClass("active");
          headerSelector.addClass("active");
          table.dataTable().fnFilter(facetValue, index);
        }
      });

      headerSelector.on("click", (e) => {
        if (window.innerWidth >= 992) {
          // Desktop: enable disable event for header title
          const element = $(e.currentTarget);
          if (element.hasClass("active")) {
            facetSelector.removeClass("active");
            headerSelector.removeClass("active");
            $(".dataTable").dataTable().fnFilter("", index);
          }
        } else if (containerSelector.hasClass("visible")) {
          // Mobile: enable facet visibility
          containerSelector.removeClass("visible");
        } else {
          facetsContainerSelector.removeClass("visible");
          containerSelector.addClass("visible");
        }
      });
    });
  };

  /**
   * init all required functions
   *
   * @return {void}
   */
  const documentReadyInit = () => {
    initDataTableAnnualOverview();
    observeAndHandleFilters();
  };

  if ($(".annualOverview").length) {
    documentReadyInit();
  }
});
