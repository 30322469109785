import $ from "jquery";
import SeminarsLibrary from "./libary";

$(document).ready(() => {
  const overviewElement = $(".bundle-order-overview");

  if (overviewElement.length) {
    const seminarDaytime = overviewElement
      .data("translation-seminar-daytimes")
      .split("|");
    const seminarsCount = overviewElement
      .data("translation-seminar-counts")
      .split("|");
    const mailForm = $("#mailform");

    let json = overviewElement.find("[data-seminar-json]").data("seminar-json");
    if (typeof json === "string") {
      json = JSON.parse(json);
    }

    if (mailForm.length) {
      // transfer values to respective formhandler fields
      $(".js-transfer-to-form").each((index, element) => {
        if (
          mailForm.find(`[name*="${$(element).attr("name")}"]`).val() === ""
        ) {
          mailForm
            .find(`[name*="${$(element).attr("name")}"]`)
            .val($(element).val());
        }
      });

      if (Object.prototype.hasOwnProperty.call(json, "bundledDataJson")) {
        if (
          mailForm.find("[name*='formhandler[seminarOverviewJson]']").val() ===
          ""
        ) {
          mailForm
            .find("[name*='formhandler[seminarOverviewJson]']")
            .val(JSON.stringify(json.bundledDataJson));
        }
      }

      if (Object.prototype.hasOwnProperty.call(json, "seminarsDates")) {
        if (
          mailForm.find("[name*='formhandler[seminarsDatesJson]']").val() === ""
        ) {
          mailForm
            .find("[name*='formhandler[seminarsDatesJson]']")
            .val(JSON.stringify(json.seminarsDates));
        }
      }

      if ($.isEmptyObject(json.bundledDataJson)) {
        json.bundledDataJson = JSON.parse(
          mailForm.find("[name*='formhandler[seminarOverviewJson]']").val()
        );
      }

      if ($.isEmptyObject(json.seminarsDates)) {
        json.seminarsDates = JSON.parse(
          mailForm.find("[name*='formhandler[seminarsDatesJson]']").val()
        );
      }
    }

    $(".js-transfer-to-order-overview").appendTo(".order-overview");

    const ajaxUrl = overviewElement.data("seminar-ajax-url");
    const library = new SeminarsLibrary();
    library.setSeminarJson(json);
    library.setAjaxUrl(ajaxUrl);

    $.each(json.bundledDataJson, (i, appointment) => {
      library.pushAppointmentInToTheOverviewOnCurrentDay(
        appointment.day,
        appointment.daytime,
        appointment.title,
        appointment.date,
        appointment.price,
        seminarDaytime[appointment.daytime - 1]
      );
    });

    // add seminar counter with translations
    $(".seminar-count").each((index, element) => {
      $(element).text(seminarsCount[index]);
    });

    // Adapt price total after submit form
    library.adaptPriceTotal();
  }
});
