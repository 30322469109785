import $ from "jquery";
import SeminarsLibrary from "./libary";

$(document).ready(() => {
  const detail = $(".seminars-detail-view");

  if (detail.length) {
    const library = new SeminarsLibrary();

    let json = detail.find("[data-seminar-json]").data("seminar-json");
    const ajaxUrl = detail.data("seminar-ajax-url");
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    library.setSeminarJson(json);
    library.setAjaxUrl(ajaxUrl);
    library.startAvailabilityCheck('[data-view="detail"]');
  }
});
