/* global jconfirm */
/* eslint class-methods-use-this: "off" */

import $ from "jquery";
import "./jquery-confirm-custom";

export default class SeminarsLibrary {
  cart = [];

  dom = null;

  daytime = {
    morning: 1,
    afternoon: 2,
    wholeDay: 3,
  };

  currency = "";

  total = 0;

  seminarJson = {};

  ajaxUrl = "";

  availabilityTimer = false;

  availabilityClassSelector = ".js-appointment-select";

  availabilityWrapperClassSelector = false;

  // Availability Functions

  setSeminarJson(json) {
    this.seminarJson = json;
  }

  setAjaxUrl(url) {
    this.ajaxUrl = url;
  }

  startAvailabilityCheck(wrapperSelector) {
    if (wrapperSelector) {
      this.availabilityWrapperClassSelector = wrapperSelector;
    }

    this.runAvailabilityCheck();

    if (this.availabilityTimer) {
      clearInterval(this.availabilityTimer);
    }
    this.availabilityTimer = setInterval(
      this.runAvailabilityCheck.bind(this),
      60000
    );
  }

  runAvailabilityCheck() {
    $.ajax({
      url: this.ajaxUrl,
      data: {
        params: Object.prototype.hasOwnProperty.call(
          this.seminarJson,
          "remainingSeatsJson"
        )
          ? this.seminarJson.remainingSeatsJson
          : {},
      },
      success: (remainingSeats) => {
        let remainingSeatsJson = remainingSeats;
        if (typeof remainingSeatsJson === "string") {
          remainingSeatsJson = JSON.parse(remainingSeatsJson);
        }

        this.setRemainingSeatsOnEachAppointment(remainingSeatsJson);
        this.setDisableClassToAllSoldOutAppointmentLinks();
        this.generateRemainingSeatsStatus(remainingSeatsJson);

        if (this.availabilityWrapperClassSelector) {
          this.removeButtonByNoAvailability(
            this.availabilityWrapperClassSelector
          );
          this.appointmentObserver(this.availabilityWrapperClassSelector);
          this.addTargetBlank(this.availabilityWrapperClassSelector);
        }
      },
    });
  }

  // Calendar Functions

  getCurrentDay(day) {
    if (
      Object.prototype.hasOwnProperty.call(this.seminarJson, "seminarsDates")
    ) {
      for (let i = 1; i <= 3; i += 1) {
        const key = `day-${i}`;
        if (
          Object.prototype.hasOwnProperty.call(
            this.seminarJson.seminarsDates,
            key
          )
        ) {
          if (day === this.seminarJson.seminarsDates[key]) {
            return i;
          }
        }
      }
    }

    return null;
  }

  pushAppointmentInToTheCalendarOnCurrentDay(
    day,
    id,
    daytime,
    title,
    price,
    parentUid
  ) {
    const currentDay = this.getCurrentDay(day);

    const html =
      '<div class="seminars-info-wrapper">' +
      "<div" +
      ` class="appointment-day-${currentDay}-daytime-${daytime} appointment-visual appointment-id-${id}"` +
      ` data-identifier="${id}"` +
      ` data-daytime="${daytime}"` +
      ` data-day="${currentDay}"` +
      ` data-price="${price || 0}"` +
      ' data-information="wrapper">' +
      // current appointment title
      `<p class="js-appointment-title">${title}</p>` +
      // remaining seats container
      `<span data-appointment-uid="${id}"></span>` +
      // seminars information tool box using jquery ui
      "</div>" +
      `<span class="js-appointment-information-button seminars-info-icon" data-parent="${parentUid}">` +
      '<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 1024 1024">' +
      '<path d="M694.857143 768v73.142857q0 14.857143-10.857143 25.714286t-25.714286 10.857143H365.714286q-14.857143 0-25.714286-10.857143t-10.857143-25.714286v-73.142857q0-14.857143 10.857143-25.714286t25.714286-10.857143h36.571428V512h-36.571428q-14.857143 0-25.714286-10.857143t-10.857143-25.714286V402.285714q0-14.857143 10.857143-25.714285t25.714286-10.857143h219.428571q14.857143 0 25.714286 10.857143t10.857143 25.714285v329.142857h36.571428q14.857143 0 25.714286 10.857143t10.857143 25.714286zM621.714286 109.714286v109.714285q0 14.857143-10.857143 25.714286t-25.714286 10.857143H438.857143q-14.857143 0-25.714286-10.857143t-10.857143-25.714286V109.714286q0-14.857143 10.857143-25.714286t25.714286-10.857143h146.285714q14.857143 0 25.714286 10.857143t10.857143 25.714286z" />' +
      "</svg>" +
      "</span>" +
      `<section class="seminars-detail-view bundled-courses-seminar-detail" data-parent="${parentUid}></section>`;

    $(`.js-calendar-day-${currentDay}-daytime-${daytime}`).append(html);
  }

  disableMorningAppointments(day) {
    $.each(
      this.getAppointmentsFor(day, this.daytime.morning),
      (i, appointment) => {
        $(appointment).addClass("disabled");
      }
    );
  }

  disableAfternoonAppointments(day) {
    $.each(
      this.getAppointmentsFor(day, this.daytime.afternoon),
      (i, appointment) => {
        $(appointment).addClass("disabled");
      }
    );
  }

  disableWholeDayAppointments(day) {
    $.each(
      this.getAppointmentsFor(day, this.daytime.wholeDay),
      (i, appointment) => {
        $(appointment).addClass("disabled");
      }
    );
  }

  enableMorningAppointments(day) {
    $.each(
      this.getAppointmentsFor(day, this.daytime.morning),
      (i, appointment) => {
        $(appointment).removeClass("disabled");
      }
    );
  }

  enableAfternoonAppointments(day) {
    $.each(
      this.getAppointmentsFor(day, this.daytime.afternoon),
      (i, appointment) => {
        $(appointment).removeClass("disabled");
      }
    );
  }

  enableWholeDayAppointments(day) {
    $.each(
      this.getAppointmentsFor(day, this.daytime.wholeDay),
      (i, appointment) => {
        $(appointment).removeClass("disabled");
      }
    );
  }

  enableAppointment(appointment) {
    $(appointment).removeClass("disabled");
  }

  getAppointmentsFor(day, daytime) {
    return this.dom
      .find(`.appointment-day-${day}-daytime-${daytime}`)
      .not(".available-seats-status-sold-out");
  }

  setAvailability(currentAppointment, isCurrentAppointmentEnabled) {
    const currentAppointmentDay = currentAppointment.attr("data-day");
    const currentAppointmentDaytime = currentAppointment.attr("data-daytime");
    const sameDayAppointments = this.dom.find(
      `.appointment-day-${currentAppointmentDay}-daytime-1, .appointment-day-${currentAppointmentDay}-daytime-2, .appointment-day-${currentAppointmentDay}-daytime-3`
    );

    // if current appointment will be booking
    if (isCurrentAppointmentEnabled) {
      this.disableWholeDayAppointments(currentAppointmentDay);

      if (
        currentAppointmentDaytime === this.daytime.morning ||
        currentAppointmentDaytime === this.daytime.wholeDay
      ) {
        this.disableMorningAppointments(currentAppointmentDay);
      }

      if (
        currentAppointmentDaytime === this.daytime.afternoon ||
        currentAppointmentDaytime === this.daytime.wholeDay
      ) {
        this.disableAfternoonAppointments(currentAppointmentDay);
      }
      this.enableAppointment(currentAppointment);
    } else if (currentAppointmentDaytime === this.daytime.wholeDay) {
      this.enableWholeDayAppointments(currentAppointmentDay);
      this.enableMorningAppointments(currentAppointmentDay);
      this.enableAfternoonAppointments(currentAppointmentDay);
    } else if (currentAppointmentDaytime === this.daytime.morning) {
      this.enableMorningAppointments(currentAppointmentDay);
      if (!$(sameDayAppointments).hasClass("checked")) {
        this.enableWholeDayAppointments(currentAppointmentDay);
      }
    } else if (currentAppointmentDaytime === this.daytime.afternoon) {
      this.enableAfternoonAppointments(currentAppointmentDay);
      if (!$(sameDayAppointments).hasClass("checked")) {
        this.enableWholeDayAppointments(currentAppointmentDay);
      }
    }
  }

  generateAppointmentView() {
    if (
      Object.prototype.hasOwnProperty.call(this.seminarJson, "bundledDataJson")
    ) {
      $.each(this.seminarJson.bundledDataJson, (i, appointment) => {
        this.pushAppointmentInToTheCalendarOnCurrentDay(
          appointment.day,
          i,
          appointment.daytime,
          appointment.title,
          appointment.price,
          appointment.parent
        );

        if (this.currency === "") {
          this.currency = appointment.currency;
        }
      });
    }
  }

  observer() {
    // observes all VISUAL BOXES
    this.dom.find(".appointment-visual").on("click", (e) => {
      const element = $(e.currentTarget);

      // if current Appointment is not disabled
      if (!element.hasClass("disabled")) {
        let currentAppointmentStatus = false;
        element.toggleClass("checked");
        if (element.hasClass("checked")) {
          currentAppointmentStatus = true;
          this.addToCart(element);
        } else {
          this.removeFromCart(element);
        }
        this.setAvailability(element, currentAppointmentStatus);
      }
    });

    this.dom.find(".js-appointment-information-button").on("click", (event) => {
      const element = $(event.currentTarget);
      const identifier = `.js-appointment-${element.attr("data-parent")}`;
      const appointmentTitle = element
        .parent()
        .find(".js-appointment-title")
        .text()
        .trim();

      // add the current appointment price to the parent seminar and then add define the content for the overlay
      this.addAppointmentPriceRegularValue(element);

      const content = $(identifier).html();

      jconfirm({
        content,
        columnClass: "col-md-12",
        theme: "dark",
        title: "",
        buttons: {
          ok: {
            action: () => {
              // do nothing
            },
          },
        },
        onOpen: () => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "overlay.open",
            overlayname: appointmentTitle,
          });
        },
        onClose: () => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ event: "overlay.close" });
        },
      });
    });

    // Disable submit if no course registration is selected
    $(".bundled-courses-submit-form").bind("submit", (e) => {
      if ($(".js-hidden-fields").children().length >= 1) {
        return true;
      }

      e.preventDefault();
      return false;
    });
  }

  initCalendar() {
    this.dom = $(".js-calendar");
    this.generateAppointmentView();
    this.observer();
  }

  setClickEventOnCurrentAppointment(uid) {
    $(`.appointment-id-${uid}`).trigger("click");
  }

  // Detail Functions for remaining seats

  setRemainingSeatsOnEachAppointment(remainingSeatsJson) {
    $.each(remainingSeatsJson, (appointmentUid, remainingSeats) => {
      let availableSeats = "available";
      const selector = `[data-appointment-uid="${appointmentUid}"]`;

      if (remainingSeats <= 2 && remainingSeats >= 1) {
        availableSeats = "critical";
      } else if (remainingSeats < 1) {
        availableSeats = "sold-out";
      }

      $(
        `${this.availabilityClassSelector}[data-appointment="${appointmentUid}"],${selector}`
      )
        .removeClass("available-seats-status-sold-out")
        .removeClass("available-seats-status-critical")
        .removeClass("available-seats-status-available")
        .addClass(`available-seats-status-${availableSeats}`);

      $(selector).text(remainingSeats);
    });
  }

  // Detail Functions for remaining seats

  generateRemainingSeatsStatus(currentRemainingSeats) {
    $.each(currentRemainingSeats, (currentUid, remainingSeats) => {
      const currentAppointment = $(`[data-identifier=${currentUid}]`);

      currentAppointment.removeClass("available-seats-status-critical");
      currentAppointment.removeClass("available-seats-status-sold-out");
      currentAppointment.removeClass("available-seats-status-available");

      if (remainingSeats > 3) {
        currentAppointment.addClass("available-seats-status-available");
      } else if (remainingSeats <= 3 && remainingSeats >= 1) {
        currentAppointment.addClass("available-seats-status-critical");
      } else if (remainingSeats < 1) {
        if (currentAppointment.hasClass("checked")) {
          $(currentAppointment).trigger("click");
          // @todo meldung wenn kurs ausgebucht
        }
        currentAppointment.addClass("available-seats-status-sold-out disabled");
      }
    });
  }

  addToCart(domObject) {
    const uid = domObject.attr("data-identifier");
    const price = parseInt(domObject.attr("data-price"), 10);
    this.cart.push(uid);
    this.addAmountToTotal(price);
    this.addToForm(uid);
  }

  removeFromCart(domObject) {
    const uid = domObject.attr("data-identifier");
    const price = parseInt(domObject.attr("data-price"), 10);
    const removableIdentifierPosition = this.cart.indexOf(uid);

    this.cart.slice(removableIdentifierPosition, 1);
    this.removeAmountFromTotal(price);
    this.removeFromForm(uid);
  }

  addToForm(uid) {
    $(".js-hidden-fields").append(
      `<input type="hidden" value="${uid}" name="tx_heidenhainforms_contactform[seminars][]" />`
    );
  }

  removeFromForm(uid) {
    this.dom.find(`.js-hidden-fields input[value=${uid}]`).remove();
  }

  addAmountToTotal(amount) {
    this.total += amount;
    this.displayTotal();
  }

  removeAmountFromTotal(amount) {
    this.total -= amount;
    this.displayTotal();
  }

  displayTotal() {
    this.dom.find(".js-price-total").text(`${this.currency} ${this.total},-`);
  }

  setDisableClassToAllSoldOutAppointmentLinks() {
    const soldOutItems = $(
      "span.js-remaining-seats.available-seats-status-sold-out"
    );

    $.each(soldOutItems, (i, item) => {
      $(item).parent().parent().find(".seminars-link").addClass("hide-link");
    });
  }

  // Detail Functions for remaining seats

  removeButtonByNoAvailability(wrapper) {
    if (
      $(wrapper).find(".js-appointment-select .available-seats-status-critical")
        .length === 0 &&
      $(wrapper).find(
        ".js-appointment-select .available-seats-status-available"
      ).length === 0
    ) {
      const linkTarget = $(wrapper)
        .find(".js-request-link-by-no-availability")
        .attr("data-appointment-link");
      const linkTitle = $.trim(
        $(wrapper)
          .find(".js-request-link-by-no-availability")
          .first()
          .find(".js-button-text")
          .text()
      );

      this.addSeminarPriceRegularValue(wrapper);

      if (linkTarget.length <= 0 || linkTitle.length <= 0) {
        $(wrapper).find(".js-detail-booking-link").attr("href", "#").hide();
      } else {
        $(wrapper).find(".js-detail-booking-link").attr("href", linkTarget);
        $(wrapper).find(".js-detail-booking-link-text").text(linkTitle);
      }
    }
  }

  appointmentObserver(wrapper) {
    $(wrapper)
      .find(".js-appointment-select")
      .on("click", (e) => {
        const element = $(e.currentTarget);
        if (!element.hasClass("available-seats-status-sold-out")) {
          const linktarget = element.attr("data-appointment-link");
          const priceRegular = element.find(".js-seminar-priceRegular").html();

          $(wrapper).find(".js-appointment-select").removeClass("selected");
          $(wrapper).find(".js-appointment-select").addClass("disable");
          element.removeClass("disable");
          element.addClass("selected");
          $(wrapper).find(".js-detail-booking-link").attr("href", linktarget);
          $(wrapper)
            .find('[data-information="priceRegular"]')
            .html(priceRegular);
        }
      });
    this.selectFirstAvailableAppointment();
  }

  selectFirstAvailableAppointment() {
    let availableAppointments = $(".js-appointment-select").not(
      ".available-seats-status-sold-out"
    );

    if ($(".jconfirm").length === 1) {
      availableAppointments = $(".jconfirm .js-appointment-select").not(
        ".available-seats-status-sold-out"
      );
    }
    if (availableAppointments.length) {
      $(availableAppointments[0]).trigger("click");
    }
  }

  pushAppointmentInToTheOverviewOnCurrentDay(
    day,
    daytime,
    title,
    date,
    price,
    daytimeText
  ) {
    const currentDay = this.getCurrentDay(day);
    const dayClass = `.js-calendar-day-${currentDay}-daytime-${daytime}`;

    const html =
      '<div><span class="seminar-count"></span>' +
      `<span class="price">${price},-</span></div>` +
      `<div><span class="title">${title}</span></div>` +
      `<span class="date">${date}</span>` +
      `<div class="daytime-overview"><span class="daytime">${daytimeText}</span></div>`;

    $(dayClass).append(html);
  }

  adaptPriceTotal() {
    const priceTotal = $(".js-price-total");

    if (priceTotal.val() === "") {
      priceTotal.text($("[name*='formhandler[priceTotal]']").val());
    }
  }

  addAppointmentPriceRegularValue(appointment) {
    const priceRegular = appointment
      .parent()
      .find('[data-information="wrapper"]')
      .attr("data-price");
    const parentUid = appointment.attr("data-parent");

    this.dom
      .find(`.js-appointment-${parentUid}`)
      .find('[data-information="priceRegular"]')
      .html(priceRegular);
  }

  addSeminarPriceRegularValue(wrapper) {
    const identifier = $(".js-request-link-by-no-availability");
    const priceRegular = $(identifier).find(".js-seminar-priceRegular").html();

    $(wrapper).find('[data-information="priceRegular"]').html(priceRegular);
  }

  addTargetBlank(wrapper) {
    $(wrapper).find('a[href$=".pdf"]').attr("target", "_blank");
  }
}
